import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import axios from "axios";
import { useRef, useState } from "react";
import Swal from "sweetalert2";
import "./App.css";
import Logo from "./images/juck.png";

const Input = styled("input")({
  display: "none"
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#dcdcdc",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary
}));

function App() {
  const [query, setQuery] = useState("idle");
  const [token, setToken] = useState("");
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [checked, setChecked] = useState(true);
  const [iosDevchecked, setIosDevchecked] = useState(false);
  const inputRef = useRef();

  const clickPush = () => {
    if (token === "" || url === "") {
      Swal.fire({
        title: "Error!",
        text: "token 또는 url 이 비어있습니다.",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }
    setQuery("progress");
    handleSubmit();
  };

  const handleTokenChange = (e) => {
    setToken(e.target.value);
  };

  const handleUrlChange = (e) => {
    // let text = e.target.value;
    // text = text.replace("\r\n", "<br>");
    setUrl(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const clearAllData = () => {
    setToken("");
    setUrl("");
    setTitle("");
    inputRef.current.value = "";
  };

  const handleSubmit = () => {
    let serverUrl = "http://bonkab.com:8080/token";
    
    const params = { token:token, url:url, title:title };
    return axios.get(serverUrl, {
      headers: {
        "account" : token,
        "password" : url,
        "country": "KR",
        "phase": checked ? "OP" : "QA"
      }
      }).then((res) => {
      if (res.data.token === undefined) {
        throw Error;
      }
      let param = {
        title: "성공!",
        text: "성공했어요.\n" + res.data.token,
        icon: "success",
        confirmButtonText: "OK"
      };
      Swal.fire(param).then((result) => {
      
      });
    }).catch((err) => {
      Swal.fire({
        title: "실패!",
        text: "실패했어요.",
        icon: "error",
        confirmButtonText: "OK"
      });
    });
  };

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleIosDevChange = (e) => {
    setIosDevchecked(e.target.checked);
  };


  return (
    <div className="App">
      <Box sx={{ width: "90%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <img src={Logo} width={200} />
          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
              <TextField id="outlined-basic" label="ID" variant="outlined" onChange={handleTokenChange} sx={{ m: 1, width: "90%" }} value={token} />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
              <TextField
                id="filled-textarea"
                label="Password"
                placeholder="설명을 입력하세요."
                multiline
                value={url}
                variant="outlined"
                sx={{ m: 1, width: "90%" }}
                onChange={handleUrlChange}
              />
            </Item>
          </Grid>
          {/* <Grid item xs={12}>
            <Item elevation={0}>
              <TextField id="outlined-basic" label="Title" variant="outlined" onChange={handleTitleChange} sx={{ m: 1, width: "90%" }} value={title} />
            </Item>
          </Grid> */}
          <Grid item xs={12}>
            <Item elevation={0}>
            <FormControlLabel control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label="OP" />
            </Item>
            <Item elevation={0}>
            {/* <FormControlLabel control={
              <Checkbox
                checked={iosDevchecked}
                onChange={handleIosDevChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label="iosDev" /> */}
            </Item>
          </Grid>

          <Grid item xs={12}>

          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">


                <Button variant="contained" component="span" onClick={clickPush}>
                  Get
                </Button>
              </Stack>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default App;
